import Product1 from "images/product-img-1.jpg";
import Product2 from "images/product-img-2.jpg";
import Product3 from "images/product-img-3.jpg";
import Product4 from "images/product-img-4.jpg";
import Product5 from "images/product-img-5.jpg";
import Product6 from "images/product-img-6.jpg";
import Product7 from "images/product-img-7.jpg";
import Product8 from "images/product-img-8.png";

const transitionImg = [
  {
    id: 1,
    src: Product1,
    alt: "brown wallet",
  },
  {
    id: 2,
    src: Product2,
    alt: "white wallet",
  },
  {
    id: 3,
    src: Product3,
    alt: "blue wallet",
  },
  {
    id: 4,
    src: Product4,
    alt: "red wallet",
  },
  {
    id: 5,
    src: Product5,
    alt: "gray wallet",
  },
  {
    id: 6,
    src: Product6,
    alt: "small wallet",
  },
  {
    id: 7,
    src: Product7,
    alt: "opened wallet",
  },
  {
    id: 8,
    src: Product8,
    alt: "yellow wallet",
  },
];

export default transitionImg;
