import Sold1 from "images/product-img-2.jpg";
import Sold2 from "images/product-img-5.jpg";
import Sold3 from "images/product-img-1.jpg";
import Sold4 from "images/product-img-6.jpg";
import Sold5 from "images/product-img-3.jpg";
import Sold6 from "images/product-img-7.jpg";
import Sold7 from "images/product-img-8.png";
import Sold8 from "images/product-img-4.jpg";

const bestSellers = [
  {
    id: 1,
    src: Sold1,
    alt: "one of the most sold",
    type: "TRI Mini - Siyah",
    price: "₺ 99,00",
  },
  {
    id: 2,
    src: Sold2,
    alt: "one of the most sold",
    type: "TRI Mini - Aci Kahve",
    price: "₺ 99,00",
  },
  {
    id: 3,
    src: Sold3,
    alt: "one of the most sold",
    type: "TRI Mini - Kirmizi",
    price: "₺ 99,00",
  },

  {
    id: 4,
    src: Sold4,
    alt: "one of the most sold",
    type: "TRI Vertical - Kirmizi",
    price: "₺ 99,00",
  },
  {
    id: 5,
    src: Sold5,
    alt: "one of the most sold",
    type: "TRI Vertical - Siyah",
    price: "₺ 99,00",
  },
  {
    id: 6,
    src: Sold6,
    alt: "one of the most sold",
    type: "TRI Mini - Sari",
    price: "₺ 99,00",
  },
  {
    id: 7,
    src: Sold7,
    alt: "one of the most sold",
    type: "TRI - Gri",
    price: "₺ 99,00",
  },
  {
    id: 8,
    src: Sold8,
    alt: "one of the most sold",
    type: "TRI Bel Cantasi - Taba",
    price: "₺ 99,00",
  },
];

export default bestSellers