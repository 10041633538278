export const MenuItems = [
  {
    id: 1,
    title: "navbar.links.new",
    path: "/",
  },
  {
    id: 2,
    title: "navbar.links.wallets",
    path: "/",
  },
  {
    id: 3,
    title: "navbar.links.tseries",
    path: "/",
  },
  {
    id: 4,
    title: "navbar.links.cardholder",
    path: "/",
  },
  {
    id: 5,
    title: "navbar.links.travelwallet",
    path: "/",
  },
  {
    id: 6,
    title: "navbar.links.a5",
    path: "/",
  },
];
