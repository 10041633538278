import { useTranslation } from "react-i18next";
import ProductCard from "../ProductCard/ProductCard";
import bestSellers from "utils/data/bestSellers";
import styles from "./BestSellers.module.scss";


export default function BestSellers() {
  const { t } = useTranslation("common");

  return (
    <div className={styles.sales}>
      <h3 className={styles.title}>{t("bestseller.title")}</h3>
      <div className={styles.bestSellersDisplay}>
        {bestSellers.map((sold) => (
          <ProductCard key={sold.id} {...sold} />
        ))}
      </div>
    </div>
  );
}
