import HomePage from "pages/homepage/HomePage";

import "styles/main.scss";

function App() {
  return (
    <div>
      <HomePage />
    </div>
  );
}

export default App;
