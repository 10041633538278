import { productDetails } from "utils/data/heroImages";
import styles from "./Products.module.scss";
import ProductCard from "../ProductCard/ProductCard";
import { useTranslation } from "react-i18next";

const Products = () => {
  const { t } = useTranslation("common");

  return (
    <div className={styles.sales}>
      <h3 className={styles.title}>{t("products.title")}</h3>
      <div className={styles.productsDisplay}>
        {productDetails.map((detail) => (
          <ProductCard key={detail.id} {...detail} />
        ))}
      </div>
    </div>
  );
};

export default Products;
