import Img1 from "images/hero-img-1.jpg";
import Img2 from "images/hero-img-2.jpg";
import Img3 from "images/hero-img-3.jpg";
import Product1 from "images/product-img-1.jpg";
import Product2 from "images/product-img-2.jpg";
import Product3 from "images/product-img-3.jpg";
import Product4 from "images/product-img-4.jpg";

export const heroImages = [
  {
    src: Img1,
    alt: "phone cover",
  },
  {
    src: Img2,
    alt: "big wallet",
  },
  {
    src: Img3,
    alt: "small wallet",
  },
];

export const productDetails = [
  {
    id: 1,
    src: Product1,
    alt: "open brown wallet",
    type: "LETO - Taba",
    price: "₺ 99,00",
  },
  {
    id: 2,
    src: Product2,
    alt: "small brown wallet",
    type: "LETO - Bordo Wallet",
    price: "₺ 99,00",
  },
  {
    id: 3,
    src: Product3,
    alt: "small purple wallet",
    type: "LETO - Bordo",
    price: "₺ 99,00",
  },
  {
    id: 4,
    src: Product4,
    alt: "Add to the basket",
    type: "TRI Bel Cantasi - Taba",
    price: "₺ 99,00",
  },
];
