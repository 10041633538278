import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonEN from "./locales/en/common.json";
import commonTR from "./locales/tr/common.json";

i18n

  .use(initReactI18next)

  .init({
    debug: true,
    fallbackLng: "tr",
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,

    resources: {
      en: {
        common: commonEN,
      },
      tr: {
        common: commonTR,
      },
    },
  });

export default i18n;
