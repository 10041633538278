import React from "react";
import { useTranslation } from "react-i18next";
import ImageSlider from "components/ImageSlider/ImageSlider";
import { heroImages } from "utils/data/heroImages";
import styles from "./HeroSection.module.scss";

const HeroSection = () => {
  const { t } = useTranslation("common"); 

  return (
    heroImages.length > 0 && (
      <div className={styles.heroSection}>
        <ImageSlider
          images={heroImages}
          customButtons={true}
          sliderArrows={styles.sliderArrows}
          slideLeft={styles.slideLeft}
          slideRight={styles.slideRight}
          sliders={styles.sliders}
        />
        <div className={styles.scrollDownWrapper}>
          <div className={styles.scrollDown}>
            <div className={styles.lineScroll}></div>
            <span className={styles.scrollTxt}>{t("heroSection.scrollText")}</span>
          </div>
        </div>
      </div>
    )
  );
};

export default HeroSection;
