import React from "react";
import { useTranslation } from "react-i18next";
import {
  footerLinksGroup1,
  footerLinksGroup2,
  footerSocialMedia,
} from "utils/data/footer";
import Logo from "images/Logo.svg";

import styles from "./Footer.module.scss";

const Footer = () => {
  const { t } = useTranslation("common");

  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerContent}>
        <div className={styles.footerText}>
          <img className={styles.footerLogo} src={Logo} alt="Logo" />
          <p className={styles.footerTitleEl}>{t("footer.title")}</p>
          <p className={styles.footerTextEl}>{t("footer.description")}</p>
          <p className={styles.footerTextEl}>akgumusdesign@gmail.com</p>
          <div className={styles.footerSocials}>
            {footerSocialMedia.map((social, index) => (
              <a
                key={index}
                href={social.link}
                target="_blank"
                rel="noopener noreferrer"
                title={social.title}
              >
                <img
                  src={social.icon}
                  alt={social.title}
                  className={styles.footerSocialIcon}
                />
              </a>
            ))}
            ;
          </div>
        </div>

        <div className={styles.footerLinksDiv}>
          {footerLinksGroup1.map((link, index) => (
            <a
              key={index}
              href={link.path}
              className={styles.footerLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className={styles.footerTextEl}>{t(link.text)}</p>
            </a>
          ))}
        </div>

        <div className={styles.footerLinksDiv}>
          {footerLinksGroup2.map((link, index) => (
            <a
              key={index}
              href={link.path}
              className={styles.footerLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className={styles.footerTextEl}>{t(link.text)}</p>
            </a>
          ))}
        </div>

        <div className={styles.footerEmailBox}>
          <form className={styles.footerForm}>
            <h3 className={styles.footerTitleEl}>{t("footer.newsletter")}</h3>
            <div className={styles.formInput}>
              <input
                type="email"
                placeholder={t("footer.email")}
                className={styles.footerInputEl}
              />
              <button type="submit" className={styles.footerFormBtn}>
                {t("footer.subscribe")}
              </button>
            </div>
          </form>
          <p className={styles.footerTextEl}>{t("footer.copyright")}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
