import FacebookIcon from "images/facebook.svg";
import InstagramIcon from "images/instagram.svg";

export const footerSocialMedia = [
  {
    title: "Instagram",
    link: "https://www.instagram.com/akgumusdesign/",
    icon: InstagramIcon,
  },
  {
    title: "Facebook",
    link: "https://www.facebook.com/akgumusdesign/",
    icon: FacebookIcon,
  },
];

export const footerLinksGroup1 = [
  {
    path: "/",
    text: "footer.links.aboutus",
  },
  {
    path: "/",
    text: "footer.links.sales",
  },
  {
    path: "/",
    text: "footer.links.press",
  },
  {
    path: "/",
    text: "footer.links.contact",
  },
  {
    path: "/",
    text: "footer.links.product",
  },
];

export const footerLinksGroup2 = [
  {
    path: "/",
    text: "footer.links.dataPrivacy",
  },
  {
    path: "/",
    text: "footer.links.return",
  },
  {
    path: "/",
    text: "footer.links.cookies",
  },
  {
    path: "/",
    text: "footer.links.salesAgreement",
  },
];
