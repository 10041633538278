import React from "react";
import { useTranslation } from "react-i18next"; 
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import styles from "./PrimaryHeader.module.scss";

const PrimaryHeader = () => {
  const { t } = useTranslation("common");
  
  return (
    <div className={styles.container}>
      <div className={styles.primaryHeader}>
        <span className={styles.primaryHeaderText}>
          {t("primaryHeader.text")}
        </span>
        <LanguageSwitcher />
      </div>
    </div>
  );
};

export default PrimaryHeader;
