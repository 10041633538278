import React from "react";
import Navbar from "components/Navbar/Navbar";
import HeroSection from "components/HeroSection/HeroSection";
import PrimaryHeader from "components/PrimaryHeader/PrimaryHeader";
import Products from "components/Products/Products";
import BestSellers from "components/BestSellers/BestSellers";
import Footer from "components/Footer/Footer";

const HomePage = () => {
  return (
    <div>
      <PrimaryHeader />
      <Navbar />
      <HeroSection />
      <Products />
      <BestSellers />
      <Footer />
    </div>
  );
};

export default HomePage;
