import React, { useState, useEffect } from "react";
import i18n from "i18n";
import { Select } from "antd";
import { DownOutlined } from "@ant-design/icons";

import styles from "./LanguageSwitcher.module.scss";
import "antd/dist/antd.min.css";

const { Option } = Select;

const LanguageSwitcher = () => {
  const [language, setLanguage] = useState("tr");

  const switchLanguage = (lng) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  useEffect(() => {
    setLanguage(localStorage.getItem("language"));
  }, []);

  return (
    <div>
      <Select
        suffixIcon={<DownOutlined />}
        defaultValue={language}
        className={styles.selectLang}
        bordered={false}
        onChange={switchLanguage}
      >
        <Option value="en" className={styles.langOptions}>
          EN
        </Option>
        <Option value="tr" className={styles.langOptions}>
          TR
        </Option>
      </Select>
    </div>
  );
};

export default LanguageSwitcher;
