import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RiMenu3Fill, RiCloseFill } from "react-icons/ri";
import { MenuItems } from "utils/data/navMenu";
import Logo from "images/Logo.svg";
import User from "images/user.svg";
import Cart from "images/cart.svg";
import Search from "images/search.svg";

import styles from "./Navbar.module.scss";

const Navbar = () => {
  const { t } = useTranslation("common");
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className={styles.navContainer}>
      <div className={styles.navBar}>
        <div className={styles.navLogo}>
          <Link to="/">
            <img src={Logo} alt="contrast logo" className={styles.navLogoIcon} />
          </Link>
        </div>
        <div className={styles.navLinks}>
          {MenuItems.map((item) => {
            return (
              <Link key={item.id} to={item.path}>
                <span className={styles.navLink}>{t(item.title)}</span>
              </Link>
            );
          })}
        </div>
        <div className={styles.navIcons}>
          <div className={styles.navIcon}>
            <Link to="/">
              <img src={User} alt="user icon" />
            </Link>
          </div>
          <div className={styles.navIcon}>
            <Link to="/">
              <img src={Search} alt="search icon" />
            </Link>
          </div>
          <div className={styles.navIcon}>
            <div className={styles.navCart}>
              <Link to="/">
                <img src={Cart} alt="cart icon" />
              </Link>
              <span className={styles.navCartItems}>0</span>
            </div>
            <div className={styles.navMenu}>
              {showMenu ? (
                <RiCloseFill
                  color="#fff"
                  size={27}
                  onClick={() => setShowMenu(false)}
                />
              ) : (
                <RiMenu3Fill
                  color="#fff"
                  size={27}
                  onClick={() => setShowMenu(true)}
                />
              )}
              {showMenu && (
                <div className={styles.navMenuContainer}>
                  {MenuItems.map((item) => {
                    return (
                      <Link key={item.id} to={item.path}>
                        <span className={styles.navLink}>{t(item.title)}</span>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
