import { useTranslation } from "react-i18next";
import ImageSlider from "components/ImageSlider/ImageSlider";
import transitionImg from "utils/data/transitionImg";
import Eye from "images/eye.png";
import styles from "./ProductCard.module.scss";

export default function ProductCard({ type, price }) {
  const { t } = useTranslation("common");

  return (
    <div className={styles.productCard}>
      <div className={styles.imageSliderWrapper}>
        <ImageSlider
          images={transitionImg}
          customButtons={true}
          sliderArrows={styles.arrows}
          slideLeft={styles.leftVector}
          slideRight={styles.rightVector}
          sliders={styles.sliders}
        />
      </div>
      <div className={styles.productDetails}>
        <p className={styles.productText}>{type}</p>
        <p className={styles.productText}>{price}</p>
        <div className={styles.productOption}>
          <p className={styles.addProduct}>{t("product.option")}</p>
          <img className={styles.toggleView} src={Eye} alt="" />
        </div>
      </div>
    </div>
  );
}
