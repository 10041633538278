import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade } from "swiper";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import styles from "./ImageSlider.module.scss";

const ImageSlider = ({ images, customButtons = false, sliderArrows, slideLeft, slideRight, sliders }) => {
  const swiperNavPrevRef = useRef(null);
  const swiperNavNextRef = useRef(null);

  const buttonType = customButtons
    ? {
        nextEl: swiperNavNextRef.current,
        prevEl: swiperNavPrevRef.current,
      }
    : true;

  return (
    images.length > 0 && (
      <Swiper
        modules={[Navigation, EffectFade]}
        speed={200}
        navigation={buttonType}
        effect={"fade"}
        slidesPerView={1}
        loop={true}
        className={styles.sliderWrapper}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = swiperNavPrevRef.current;
          swiper.params.navigation.nextEl = swiperNavNextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} className={sliders}>
            <img src={image.src} alt={image.alt} />
          </SwiperSlide>
        ))}
        {customButtons && (
          <div className={sliderArrows}>
            <div className={slideLeft} ref={swiperNavPrevRef}></div>
            <div className={slideRight} ref={swiperNavNextRef}></div>
          </div>
        )}
      </Swiper>
    )
  );
};

export default ImageSlider;
